import React from "react";
import Helmet from "react-helmet";
import Artwork from "src/components/Artwork/Artwork";

import Footer from "src/storyblok/GlobalModules/Footer/Footer";
import SEO from "src/components/SEO/SEO";
import { getSeo } from "src/helpers/getSeo";

import * as styles from "./Layout.module.scss";
import { Props } from "./types";

export default ({ children, pageContext }: Props): JSX.Element => {
  const { globalData, story }: any = pageContext ? pageContext : {};

  const { footer, seo, site }: any = globalData ? globalData : {};

  const seo_data: any = seo ? seo.content : {};
  const { seo_description, seo_title, seo_image } = getSeo(seo_data);

  const { background, background_mobile } =
    site && site.content ? site.content : {};

  return (
    <div className={styles.layout} id="layout">
      <React.Fragment>
        {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )}
        <SEO
          title={story?.name && story?.slug !== "home" ? story.name : ""}
          metaDescription={seo_description}
          image={seo_image}
        />
      </React.Fragment>

      <Artwork
        background={
          background && background.filename ? background.filename : null
        }
        backgroundMobile={
          background_mobile && background_mobile.filename
            ? background_mobile.filename
            : null
        }
      />

      <main className={styles.page}>{children}</main>
      {footer?.content && <Footer blok={footer.content} />}
    </div>
  );
};
