import React from "react";
import { Flex, Box } from "reflexbox";

export const Container = (props: any) => <Box {...props} />;

Container.defaultProps = {
  paddingX: [4, null, null, 10],
  width: 1,
};

export const Row = (props: any) => <Flex {...props} />;

Row.defaultProps = {
  marginX: ["-8px", null, null, "-12px"],
  flexDirection: ["column", null, null, "row"],
  flexWrap: "wrap",
};

export const Column = (props: any) => <Box {...props} />;

Column.defaultProps = {
  position: "relative",
  width: "100%",
  minHeight: "1px",
  paddingX: [2, null, null, 3],
};
