import React from "react";
import { Location } from "@reach/router";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "emotion-theming";

import "focus-visible";
import { Props } from "./types";

const theme = {
  breakpoints: ["374px", "767px", "1024px", "1099px", "1920px"],
  space: [...Array(60)].map((_, i) => i * 4),
};

export default ({ element }: Props): JSX.Element => {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Location>
          {({ location }) => {
            return element;
          }}
        </Location>
      </ThemeProvider>
    </RecoilRoot>
  );
};
